<template>
  <div class="container">
    <img src="@/assets/logo.png" class="logo" />
    <div class="text">
      ProjectClimb is a revolutionary tool that transforms the climbing wall
      experience through projections, cameras, and artificial intelligence. This
      product simplifies, enhances, and makes climbing training more enjoyable.
      <br />
      <br />
      It addresses the challenge of effectively displaying climbing routes and
      analyzing performance using advanced technology in a more integrated
      manner.
    </div>
    <div>
      <div class="logos">
        <a href="https://evropskasredstva.si" target="_blank"
          ><img src="@/assets/eu.png" /></a
        ><img src="@/assets/ifeelslovenia.png" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text {
  color: rgb(75, 133, 180);
  transform: translateY(-40px);
  font-weight: 200;
  max-width: 700px;
  width: 100%;

  margin-top: 20px;
}

.logo {
  width: 600px;
  max-width: 100%;
  // margin-bottom: 120px;
  margin-top: 120px;

  // transform: translateX(-20px);
}
.logos {
  img {
    height: 63px;
    cursor: pointer;
    &:first-child {
      position: fixed;
      left: 40px;
      bottom: 30px;
    }
    &:nth-child(2) {
      position: fixed;
      right: 40px;
      bottom: 30px;
    }
  }
  width: 600px;
  display: flex;
  justify-content: space-between;
}
</style>
